.buttons-cointainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button-button{
    padding: 10px;
    box-shadow: 0px 1.91224px 1.91224px rgba(0, 0, 0, 0.25);
    margin: 5px;
}

.button-clicked{
    background-color: #F5BB36;
}

.button-align-right{
    padding: 5px;
    padding-top: 9px;
}

.ionContentViewport{
    margin-bottom: 300px;
}

div[scrollx=true], div[scrolly=true] {
    position: relative;
    overflow: hidden;
  }

  div[scrollx=true], div[scrolly=true]::-webkit-scrollbar{
    display: none;
  }
  
  div[scrollx=true] {
    overflow-x: auto;
  }
  
  div[scrolly=true] {
    overflow-y: auto;
  }

.scrollX::-webkit-scrollbar{
    display: none;
}

.scrollX{
    position: relative;
    overflow: auto;
}

.div-btn-toolbar-bottoms {
  justify-items: center;
  display: inline-block;
}
.div-btn-toolbar-bottoms p {
  user-select: none;
  font-size: 12px;
  margin: inherit;
  margin-top: -5px;
}
.div-btn-toolbar-bottom {
  margin: inherit;
  float: left;
  width: 115px;
  height: 100%;
  cursor: pointer;
  color: white;
}
.div-btn-toolbar-bottom:hover {
  background-color: #4e4e4a;
}
.div-btn-toolbar-bottom:active {
  background-color: #404237
}
.icon-btn-toolbar-bottom {
  font-size: 25px;
}

.badge-btn-notification-produto {
  position: absolute;
  cursor: pointer;
  margin-top: -4px;
  margin-left: -5px;
  z-index: 99999;
}
ion-badge.badge-btn-notification-produto {
  font-size: 11px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .div-btn-toolbar-bottoms p {
    font-size: 10px;
    margin: inherit;
  }
  .btn-novo-pedido {
    font-size: 10px;
  }
}