.anexo-img {
  height: 100%;
}
.card-anexo {
  height: 170px !important;
}
.content-card-anexo {
  height: 135px !important;
  cursor: pointer;
  text-align: center;
}
.content-card-anexo .div-top {
  height: 70%;
}
.content-card-anexo .div-bottom {
  height: 30%;
}
.content-card-anexo .div-bottom span {
  font-size: 12px;
}
.descricao-anexo {
  text-align: center !important;
}