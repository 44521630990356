ion-toolbar {
  --background: #404237;
}
ion-header.ionGridViewport-border-red {
  border-bottom: 5px solid #FF2929;
}
ion-grid.ionGridViewport {
  --ion-grid-padding: none;
}

ion-title.titleCenterWhite {
  text-align: center;  
  color: white;
}
ion-title.titleCenterBlack {
  text-align: right;  
  color: black;
}

ion-col.ionColViewport {
  padding:0;
  --ion-grid-column-padding: none;
  --ion-grid-column-align  : center;
}

ion-fab {
  margin-top: 25px;
}

ion-searchbar {
  border: 0px;
  --box-shadow: 0px 0px 0px 0px;
  box-shadow: 0px;
  text-align: left !important;
  padding: 0;
}

ion-button {
  font-size: 12px;
}

ion-toolbar.bakground-white {
  --background: white;
  box-shadow: 0px 0px 4px 1px gray;
}
ion-toolbar.withou-css {
  --background: white;
  padding: 0;
  margin: 0;
}

.card-content-md p {
  line-height: 1 !important;
}

.sc-ion-searchbar-md-h {
  --box-shadow: 0px 0px 0px 0px;
}

.myIcon {
  height: 25px;
  width: 25px;
  margin: 5px;
}

@media only screen and (max-width: 768px) {
  .myIcon {
    height: 20px;
    width: 20px;
  }
}

@media only screen and (max-width: 368px) {
  .myIcon {
    height: 18px;
    width: 18px;
  }
}

my-custom-class{
  z-index: 1;
}

.modalProCad .modal-wrapper{
  border-radius: 10px;
}

.myPopover {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}