.modalProCad .modal-wrapper {
    width        : 100%;
    height       : 100%;
    border-radius: 0px;
}

.div-btn-toolbar-bottoms {
    justify-items: center;
    display      : inline-block;
    text-align   : center;
}

.div-btn-toolbar-bottoms p {
    user-select: none;
    font-size  : 12px;
    margin     : inherit;
    margin-top : -5px;
}

.div-btn-toolbar-bottom {
    margin: inherit;
    float : left;
    width : 115px;
    height: 100%;
    cursor: pointer;
    color : white;
}

.div-btn-toolbar-bottom:hover {
    background-color: #4e4e4a;
}

.div-btn-toolbar-bottom:active {
    background-color: #404237
}

.icon-btn-toolbar-bottom {
    font-size: 25px;
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .div-btn-toolbar-bottoms p {
        font-size: 10px;
        margin   : inherit;
    }

    .btn-novo-pedido {
        font-size: 10px;
    }
}