.card-pedido {
  height: 230px !important;
}

.content-card-pedido {
  height: 195px !important;
  cursor: pointer;
}

.content-card-pedido .div-top {
  height: 65% !important;
}

.content-card-pedido .div-bottom {
  height: 35% !important;
}

ion-icon.iconsToolbar {
  font-size: 22px;
}

.card-icon-right {
  height : 32px;
  padding: 0px 5px 0px 5px;
}

.card-icon-left {
  height : 32px;
  padding: 0px 5px 0px 5px;
}

ion-icon.card-icon-right,
.card-icon-left {
  font-size: 25px;
}

.div-finalidade {
  background-color: aqua;
  position        : absolute;
  z-index         : 1;
  margin-left     : 45px;
  padding         : 4px;
  border-radius   : 100px;
}

.span-finalidade {
  font-weight: bold;
  font-size  : 11px;
  float      : left;
}

.btn-aprovar-compra {
  width           : 50px;
  border-radius   : 10px;
  font-size       : 16px;
  margin          : 2px;
  padding         : 10px;
  background-color: green;
  color           : white;
}

.btn-reprovar-compra {
  width           : 50px;
  border-radius   : 10px;
  font-size       : 16px;
  margin          : 2px;
  padding         : 10px;
  background-color: red;
  color           : white;
}