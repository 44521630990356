ion-content.usuario {
  --background: white url('../../assets/img/fecoagro1.jpg') no-repeat center center / cover !important;
  z-index     : -999;
}

.containerAux {
  text-align: center;
  position  : absolute;
  left      : 0;
  right     : 0;
  top       : 50%;
  transform : translateY(-50%);
  box-shadow   : 0px 0px 0px rgba(0, 0, 0, 0);
}

.logomarca {
  padding-top: 10px;
  width: 280px;
  box-shadow   : 0px 0px 0px rgba(0, 0, 0, 0);
  margin-bottom: 25px;
}

.tipoAplicacaoCard {
  height: 50px;
}
.tipoAplicacao {
  position: absolute;
  font-size: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input {
  --border-width : 3px;
  --border-radius: 20px;
}

.item {
  --background: none;
  --color     : white;
}

.btnEntrar {
  --border-radius: 20px;
  width          : 100%;
  /* height      : 70px; */
  /* font-size   : 17px; */
  color          : #FEFEFE;
  box-shadow   : 0px 0px 0px rgba(0, 0, 0, 0);
}

.esqueciSenha {
  --color: white;
}

.logomarcaG3 {
  width: 180px;
  box-shadow   : 0px 0px 0px rgba(0, 0, 0, 0);
}