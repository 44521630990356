.card-produto {
  height: 250px !important;
}
.content-card-produto {
  height: 215px !important;
  cursor: pointer;
}
.content-card-produto .div-bottom-pro span {
  font-size: 12px;
}
.descricao-produto {
  text-align: center !important;
}

.div-top-pro:after {
  content: '';
  display: block;
  clear: both;
}
.div-top-pro {
  height: 45% !important;
  padding-top: 5px;
}
.div-top-pro p {
  margin: unset;
  text-align: center;
}

.div-bottom-pro {
  margin-left: 5px;
  height: 55% !important;
}