.card-filial {
  height: 190px !important;
}
.content-card-filial {
  height: 155px;
  cursor: pointer;
  overflow: auto;
}
.content-card-filial .div-bottom span {
  font-size: 12px;
}

.div-top {
  height: 60% !important;
  padding-top: 5px;
}
.div-bottom {
  margin-left: 5px;
  height: 40% !important;
}