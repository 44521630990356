#div-icon-msg-close {
    display: none;
}
.icon-msg-close {
    cursor: pointer;
}
ion-icon.icon-msg-close {
    color: red;
    fill: red;
}
ion-icon.icon-msg-close:hover {
    fill: rgb(185, 0, 0);
}
ion-icon.icon-msg-close:active {
    fill: red;
}

#div-msg-right:hover #div-icon-msg-close {
    display: block;
}