.modalProCad .modal-wrapper {
    width        : 100%;
    height       : 100%;
    border-radius: 0px;
}

ion-item.input-send {
    width        : 99%;
    --background : aliceblue;
    --color      : black;
    border-radius: 50px;
}

.icon-send,.icon-refresh {
    cursor: pointer;
}
ion-icon.icon-send:hover {
    fill: black;
}
ion-icon.icon-send:active {
    fill: rgb(70, 70, 70);
}

ion-icon.icon-refresh {
    fill: #F5BB36;
    color: #F5BB36;
}
.badge-btn-msgs {
    position: absolute;
    margin-top: 5px;
    margin-left: -65px;
    font-size: 12px;
}

.btn-scroll-msg {
    --border-radius: 40px;
}

.gif-digitando {
    height: 20px;
}

.divider {
    position: relative;
    text-align: center;
    border-bottom: 1px solid rgb(190, 190, 190);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.teste {
    position: relative;
    width: 100%;
    display: table;
    margin: 0 auto;
    margin-top: 20px;
}
.div-hr {
    background-color: rgb(221, 221, 221);
}

.div-data {
    background-color: white;
    display: table;
    margin: 0 auto;
    /* margin-top: -12px; */
}
.div-data > span {
    padding: 15px;
    color: black;
    font-size: 12px;
    font-weight: bold;
}

.data-msgs, .data-msgs-news {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}  
.data-msgs:before,
.data-msgs:after,
.data-msgs-news:before,
.data-msgs-news:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid rgb(207, 207, 207);
    margin: auto;
}

.data-margin {
    font-size: 12px !important;
    font-weight: bold;
    margin: 0px 15px 0 15px;
}

.data-msgs-news {
    -webkit-animation: cssAnimation 4s forwards; 
    animation: cssAnimation 4s forwards;
}
@keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;margin: 0;}
}
@-webkit-keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;margin: 0;}
}