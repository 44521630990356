* {
  font-size: 15px;
}
@media only screen and (max-width: 768px) {
  * {
    font-size: 12px;
  }
}
/* @media only screen and (max-width: 368px) {
  * {
    font-size: 11px;
  }
} */

html {
  scroll-behaviour: smooth,
}


:root {
  --ion-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999999;
}

/* GIF ANIMADO QUANDO ABRE A APLICAÇAO */
.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 0.5em solid #E53B3E;
    border-left: 0.5em solid #EF8588;
    border-radius: 50%;
    animation: spin 2s infinite linear;
    transition: opacity 0.3s;
    z-index: 999;
  }
  
  .loader--hide {
    opacity: 0;
    z-index: -999;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


.mainModalClass .modal-wrapper {
  width: 100vw;
  height: 100vh;
}

/* BOTÃO ADD PEDIDO, FINALIZAR */
ion-button.btn-novo-pedido {
  --border-radius: 20px;
  --background: #F5BB36;
  /* margin-right: 20px;
  margin-bottom: -10px; */
}
ion-button.btn-finalizar-pedido {
  --border-radius: 20px;
  --background: #F5BB36;
  /* margin-right: 20px;
  margin-bottom: -10px; */
}
ion-button.btn-finalizado-pedido {
  --border-radius: 20px;
  --background: #FF9248;
  /* margin-right: 20px;
  margin-bottom: -10px; */
}
.d-table {
  display:table;
}
.d-table-cell {
  display:table-cell;
}
.w-100 {
  width: 100%;
}
.tar {
  text-align: right;
  width: 50%;
}
.lef {
  text-align: left;
  width: 50%;
}