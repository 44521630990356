.buttons-cointainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button-button{
    padding: 10px;
    box-shadow: 0px 1.91224px 1.91224px rgba(0, 0, 0, 0.25);
    margin: 5px;
}

.button-clicked{
    background-color: #F5BB36;
}

.button-align-right{
    padding: 5px;
    padding-top: 9px;
}

.ionContentViewport{
    margin-bottom: 300px;
}

div[scrollx=true], div[scrolly=true] {
    position: relative;
    overflow: hidden;
  }

  div[scrollx=true], div[scrolly=true]::-webkit-scrollbar{
    display: none;
  }
  
  div[scrollx=true] {
    overflow-x: auto;
  }
  
  div[scrolly=true] {
    overflow-y: auto;
  }

.scrollX::-webkit-scrollbar{
    display: none;
}

.scrollX{
    position: relative;
    overflow: auto;
}

/* CSS GENÉRICO DOS CARDS */
.text-card {
  text-align: left;
}
.text-card b, .text-card span  {
  font-size: 12px !important;
}
.text-card-center {
  text-align: center;
}
.text-card-data, .text-card-data > b, .text-card-data > b > span {
  text-align: right;
  font-size: 12px !important;
  margin: unset;
}
.labels-card {
  float: left;
  text-align: left;
}
.result-label-card {
  display: table-cell;
  text-align: left;
}

.card-grid{
  border-collapse:separate;
  table-layout: auto;
  width: 100%;
  font-family:'Arial';
  border-collapse: collapse;
  border-spacing: 0;
  border-style: hidden;
  font-size: 15px;
}

.div-descricao-pedido {
  width: 100%;
  height: 35px;
  float: left;
  text-align: center;
  color: #6b6363;
}
.div-descricao-pedido > span {
  float: left;
  width: 100%;
  vertical-align: middle;
  margin-top: 8px;
  font-weight: bold;
}

.div-icon-left {
  float: left;
}
.card-icon-left {
  position: absolute;
  cursor: pointer;
}
.card-icon-left:hover {
  background-color: rgb(228, 228, 228);
}
.card-icon-left:active {
  background-color: rgb(189, 189, 189);
}

.div-icon-right {
  /* width: 50px; */
  float: right;
  margin-top: -35px;
  margin-right: 35px;
}
.div-icon-right-right {
  /* width: 50px; */
  float: right;
  margin-top: -35px;
  margin-right: 65px;
}
.div-icon-right-right-right {
  /* width: 50px; */
  float: right;
  margin-top: -35px;
  margin-right: 95px;
}

.card-icon-right {
  position: absolute;
  cursor: pointer;
  height: 32px;
}
.card-icon-right:hover {
  background-color: rgb(228, 228, 228);
}
.card-icon-right:active {
  background-color: rgb(189, 189, 189);
}

.badge-btn-notification {
  position: absolute;
  cursor: pointer;
  z-index: 99999;
  margin-top: -15px;
  margin-left: -15px;
}
ion-badge.badge-btn-notification {
  font-size: 11px;
}
.badge-btn-notification-filtros {
  position: absolute;
  cursor: pointer;
  margin-left: -10px;
  margin-top: 2px;
}
ion-badge.badge-btn-notification-filtros {
  font-size: 11px;
}

.card-header-pendentes {
  width: 100%;
  height: 35px;
  border-bottom: 3px solid #76DC7B;
}
.card-header-andamentos {
  width: 100%;
  height: 35px;
  border-bottom: 3px solid #599BFF;
}
.card-header-concluidos {
  width: 100%;
  height: 35px;
  border-bottom: 3px solid #FF9248;
  background-color: #fabf98;
}
.card-header-ignorados {
  width: 100%;
  height: 35px;
  border-bottom: 3px solid #c44c50;
}
ion-toolbar.card-header-pendentes, ion-toolbar.card-header-andamentos, ion-toolbar.card-header-concluidos, ion-toolbar.card-header-ignorados {
  --background: none;
  --min-height: 35px !important;
  z-index: 0;
}
ion-toolbar.card-header-buttons {
  --background: #fafafa;
  --min-height: 35px !important;
  margin-top: -35px !important;
  z-index: 0;
}

.container-card {
  height: 100%;
}
.container-card:before {
  content: '';
  height: 100%;
  float: left;
}
.div-top:after {
  content: '';
  display: block;
  clear: both;
}
.div-top {
  height: 50%;
  padding-top: 5px;
}
.div-top p {
  margin: unset;
  text-align: center;
}
.descricao-filial {
  text-align: center;
  margin: 5px;
}

.div-bottom {
  margin-left: 5px;
  height: 50%;
}
.text-card-data span, .text-card-data b {
  font-size: 12px;
}

.router-link {
  --color: none;
  text-decoration: none;
}
.content-card-pedido:hover,.content-card-produto:hover, .content-card-filial:hover, .content-card-anexo:hover {
  background-color: rgb(228, 228, 228);
}
.content-card-pedido:active, .content-card-produto:active, .content-card-filial:active, .content-card-anexo:active {
  background-color: rgb(189, 189, 189);
}

.container-card,
.container-card>div {
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="card-content"] {
    font-size: 10px
  }
  [class*="td-col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}